/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 1) inset;
  -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 1) inset;
  background-color: rgba(255, 255, 255, 1);
  transition: background-color 5000s ease-in-out 0s;
}
html,
body {
  width: 100%;
  height: 100%;
  background-color: transparent;
  overflow-anchor: none;
  overflow: unset;
}

@media print {
  body {
    zoom: 85%;
    width: 100%;
    height: 100%;
  }

  .page-break {
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 5mm 4mm;
}

table {
  page-break-inside: auto !important;
}
tr {
  page-break-inside: avoid !important;
  page-break-after: auto !important;
}
